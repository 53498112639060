// src/WeeklyRankPage.js
import React from 'react';
import './WeeklyRankPage.css';

const FhxStatistics = () => {

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://charts.mongodb.com/charts-project-0-qpmxyor/public/dashboards/677ac423-b396-4ab1-8ce7-9d0428a17de2"
        title="MongoDB Chart"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      ></iframe>
    </div>
  );
};

export default FhxStatistics;