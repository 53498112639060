// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import DailyRankPage from './DailyRankPage';
import WeeklyRankPage from './WeeklyRankPage';
import FhxStatistics from './FhxStatistics';
import FhxMobMapPage from './FhxMobMapPage';
import Layout from './components/Layout';
import './App.css'; // Import global CSS

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/daily"
          element={
            <Layout>
              <DailyRankPage />
            </Layout>
          }
        />
        <Route
          path="/weekly"
          element={
            <Layout>
              <WeeklyRankPage />
            </Layout>
          }
        />
        <Route
          path="/statistics"
          element={
            <Layout>
              <FhxStatistics />
            </Layout>
          }
        />
        <Route
          path="/mobMap"
          element={
            <Layout>
              <FhxMobMapPage />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
