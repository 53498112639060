// src/WeeklyRankPage.js
import React from 'react';
import './WeeklyRankPage.css';

const FhxMobMapPage = () => {

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://uralsaluk.com/fhxmoblist/"
        title="MongoDB Chart"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      ></iframe>
    </div>
  );
};

export default FhxMobMapPage;